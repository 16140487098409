import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getToken, clearToken } from '../../helpers/utility';
import actions from './actions';

const fakeApiCall = false; // auth0 or express JWT

export function* loginRequest(data) {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    // const { token } = payload;
    // if (token) {
    //   yield put({
    //     type: actions.LOGIN_SUCCESS,
    //     token: token,
    //     profile: 'Profile',
    //   });      
    // } else {
    //     yield put({ type: actions.LOGIN_ERROR });
    // }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*() {});
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
   localStorage.removeItem('getreal_authdata');    
   // clearToken();
   yield put(push('/'));
  });
}

export function* checkRegistration() {
  yield takeEvery(actions.CHECK_REGISTRATION, function*(payload) {
     //alert(payload.token);
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    // const token = getToken().get('idToken');
    // if (token) {
    //   yield put({
    //     type: actions.LOGIN_SUCCESS,
    //     token,
    //     profile: 'Profile',
    //   });
    //   yield put({
    //     type: actions.CHECK_REGISTRATION,
    //     token
    //   });
    // }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
