import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';

export function* addBusiness() {
  yield takeEvery(actions.ADD_CONTACT, function*() {});
}
export function* getBusiness() {
  yield takeEvery(actions.GET_CONTACTS, function*() {});
}
export function* getBusinessById() {
  yield takeEvery(actions.GET_CONTACTS, function*() {});
}
export function* getAmenities() {
  yield takeEvery(actions.GET_ALL_AMENITIES, function*() {});
}
export function* getPhotos() {
  yield takeEvery(actions.GET_ALL_PHOTOS, function*() {});
}
export function* getApartments() {
  yield takeEvery(actions.GET_ALL_PHOTOS, function*() {});
}
export function* getApartmentPhotos() {
  yield takeEvery(actions.GET_ALL_APARTMENT_PHOTOS, function*() {});
}
export function* getApartmentFloorPlans() {
  yield takeEvery(actions.GET_ALL_APARTMENT_FLOORPLANS, function*() {});
}
export function* getFloorPlans() {
  yield takeEvery(actions.GET_ALL_PHOTOS, function*() {});
}
export function* saveBusiness() {
  yield takeEvery(actions.SAVE_CONTACT, function*() {});
}
export function* editBusiness() {
  yield takeEvery(actions.EDIT_CONTACT, function*() {});
}
export function* deleteBusiness() {
  yield takeEvery(actions.DELETE__CONTACT, function*() {});
}
export default function* rootSaga() {
  yield all([
    fork(addBusiness), 
    fork(getBusinessById), 
  	fork(editBusiness),
  	fork(saveBusiness), 
  	fork(deleteBusiness), 
    fork(getBusiness),
    fork(getAmenities),
    fork(getPhotos),
    fork(getFloorPlans),
    fork(getApartments),
    fork(getApartmentPhotos),
    fork(getApartmentFloorPlans)
  ]);
}
