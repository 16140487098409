import usersActions from "./actions";

const users = {};

const initState = {
  users,
  editView: false,
  loading: false
};

export default function contactReducer(state = initState, action) {
  switch (action.type) {
    case usersActions.CHANGE_CONTACT:
      return {
        ...state,
        selectedId: action.id,
        editView: false
      };
    case usersActions.GET_USERS:
      return {
        ...state,
        users: action.payload,
        editView: false
      };
    case usersActions.IS_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case usersActions.ADD_CONTACT:
      return {
        ...state,
        users: action.users,
        selectedId: action.selectedId,
        editView: true
      };
    case usersActions.EDIT_USERS:
      return {
        ...state,
        users: action.users
      };
    case usersActions.SAVE_CONTACT:
      return {
        ...state,
        users: action.users
      };
    case usersActions.DELETE__CONTACT:
      return {
        ...state,
        users: action.contacts,
        selectedId: action.selectedId
      };
    case usersActions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view
      };
    default:
      return state;
  }
}

export { users };
