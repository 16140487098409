import { contacts } from "./reducer";

function ascendingSort(contact1, contact2) {
  const name1 = contact1.name ? contact1.name.toUpperCase() : "~";
  const name2 = contact2.name ? contact2.name.toUpperCase() : "~";
  return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

const contactActions = {
  ADD_CONTACT: "ADD_CONTACT",
  GET_CONTACTS: "GET_CONTACTS",
  EDIT_CONTACT: "EDIT_CONTACT",
  SAVE_CONTACT: "SAVE_CONTACT",
  DELETE__CONTACT: "DELETE__CONTACT",
  CHANGE_CONTACT: "CHANGE_CONTACT",
  EDIT_VIEW: "EDIT_VIEW",
  changeContact: id => ({
    type: contactActions.CHANGE_CONTACT,
    id
  }),
  getContacts: () =>{
    return (dispatch, getState) => {
      return fetch('http://localhost:5432/users')
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            console.log('tihs the body', body);
            dispatch({ type: "GET_CONTACTS", payload: body });
          }
        });
      };
  },
  addContact: () => {
    const newContact = {    
    email: '',
    master_tenant: '',
    user_id: '',
    tenant: '',
    f_name: '',
    l_name: '',
    about: '',
    photo: '',
    active: '',
    verified: '',
    created: '',
    modified: '',
    business_id: ''
    };
    return (dispatch, getState) => {
      dispatch({
        type: contactActions.ADD_CONTACT,
        contacts: [...getState().Contacts.contacts, newContact],
        selectedId: newContact.id
      });
    };
  },
  editContact: newContact => {
    return (dispatch, getState) => {
      const contacts = getState().Contacts.contacts;
      const newContacts = [];
      contacts.forEach(contact => {
        if (contact.id === newContact.id) {
          newContacts.push(newContact);
        } else {
          newContacts.push(contact);
        }
      });
      dispatch({
        type: contactActions.EDIT_CONTACT,
        contacts: newContacts.sort(ascendingSort)
      });
    };
  },
  deleteContact: id => {
    return (dispatch, getState) => {
      const contacts = getState().Contacts.contacts;
      const selectedId = getState().Contacts.selectedId;
      const newContacts = [];
      contacts.forEach(contact => {
        if (contact.id === id) {
        } else {
          newContacts.push(contact);
        }
      });
      (async () => {
        const rawResponse = await fetch('http://localhost:5432/users/delete/'+id, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
//        const content = await rawResponse;
      })();
      dispatch({
        type: contactActions.DELETE__CONTACT,
        contacts: newContacts,
        selectedId: id === selectedId ? undefined : selectedId
      });
    };
  },
  saveContact:(contact) => {    
    return (dispatch, getState) => {
      (async () => {
        const rawResponse = await fetch('http://localhost:5432/users/update/'+contact.id, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(contact)
        });
//        const content = await rawResponse;
      })();
    };    
  }, 
  viewChange: view => ({
    type: contactActions.EDIT_VIEW,
    view
  })
};
export default contactActions;
