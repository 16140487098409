import axios from 'axios';

const mailActions = {
  FILTER_ATTRIBUTE: 'FILTER_ATTRIBUTE',
  SELECTED_MAIL: 'SELECTED_MAIL',
  ALL_MAIL: 'ALL_MAIL',
  COMPOSE_MAIL: 'COMPOSE_MAIL',
  REPLY_MAIL: 'REPLY_MAIL',
  SEARCH_STRING: 'SEARCH_STRING',
  LEADS: 'LEADS',
  filterAction: newFilterAttr => {
    return (dispatch, getState) => {
      const filterAttr = getState().Mails.filterAttr;
      if (newFilterAttr) {
        if (newFilterAttr.bucket) {
          console.log('sdsdsds', newFilterAttr.bucket);
          filterAttr.bucket = newFilterAttr.bucket;
          filterAttr.tag = newFilterAttr.tag;
        } else if (newFilterAttr.tag) {
          filterAttr.tag = newFilterAttr.tag;
        }
      }
      dispatch({
        type: mailActions.FILTER_ATTRIBUTE,
        filterAttr
      });
    };
  },
  getMail: businessId => {
    return (dispatch, getState) => {
      const mail = axios.get('http://localhost:3001/emails/mail-businessid/'+businessId).then(response => {
        dispatch({
          type: mailActions.SELECTED_MAIL,
          allMails : response.data
        });

      }).catch(function(error){
        console.log('this is error', error);
      })
    };
  },
  selectMail: (selectedMail, type) => {
    return (dispatch, getState) => {
      let allMails = getState().Mails.allMails;
     
      //else{
        // allMails[
        //   allMails.findIndex(mail => mail.id === selectedMail)
        // ].read = true;  
      //}


      dispatch({
        type: mailActions.SELECTED_MAIL,
        selectedMail,
        allMails,
        kind : type
      });
    };
  },
  changeComposeMail: composeMail => ({
    type: mailActions.COMPOSE_MAIL,
    composeMail
  }),
  changeReplyMail: replyMail => ({
    type: mailActions.REPLY_MAIL,
    replyMail
  }),
  changeSearchString: searchString => ({
    type: mailActions.SEARCH_STRING,
    searchString
  }),
  getLeads: businessId => {    
    return (dispatch, getState) => {
      const leads = axios.get('http://localhost:3001/leads/all/1').then(response => {
        dispatch({
          type: mailActions.LEADS,
          leads : response.data
        });
      }).catch(function(error){
        console.log('this is error', error);
      })

    
      
    };
  }
};
export default mailActions;
