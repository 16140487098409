import actions from "./actions";

const initState = { tokens: {} };

export default function authReducer(state = initState, action) {
  switch (action.type) {      
    case actions.LOGIN_SUCCESS:
      return {
        tokens: action.payload
      };
    case actions.LOGIN_ERROR:    
      return {
        ...state,
        loginError: action.payload
      };  
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
