import companyActions from "./actions";

const company = {};

const initState = {
  company,
  editView: false
};

export default function companyReducer(state = initState, action) {
  switch (action.type) {
    case companyActions.CHANGE_COMPANY:
      return {
        ...state,
        selectedId: action.id,
        editView: false
      };
    case companyActions.GET_COMPANY:
      return {
        ...state,
        company: action.payload,
        editView: false
      };
    case companyActions.IS_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case companyActions.ADD_COMPANY:
      return {
        ...state,
        company: action.company,
        selectedId: action.selectedId,
        editView: true
      };
    case companyActions.EDIT_COMPANY:
      return {
        ...state,
        company: action.company
      };
    case companyActions.SAVE_COMPANY:
      return {
        ...state,
        company: action.company
      };  
    case companyActions.DELETE__COMPANY:
      return {
        ...state,
        company: action.company,
        selectedId: action.selectedId
      };
    case companyActions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view
      };
    default:
      return state;
  }
}

export {company};
