import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Icon, Menu } from "antd";
import { Link } from "react-router-dom";
import appActions from "../../redux/app/actions";
import userAction from '../../redux/user/actions';
import TopbarNotification from "./topbarNotification";
import TopbarMessage from "./topbarMessage";
import TopbarSearch from "./topbarSearch";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import Button from '../../components/uielements/button';
import IntlMessages from "../../components/utility/intlMessages";
import companyIcon from '../../image/avatars/company-icon.png';

import {
  HomeOutlined,
  UserOutlined,
} from '@ant-design/icons';


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const { Header } = Layout;

const {
  getUser,
  checkUser,
} = userAction;


const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed
} = appActions;

const options = [
  {
    key: 'companies',
    label: 'sidebar.company',
    icon: <HomeOutlined />
  },{
    key: 'users',
    label: 'sidebar.users',
    icon: <UserOutlined />
  }
];


const stripTrailingSlash = str => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

class Topbar extends Component {
  componentDidMount() {
    this.props.checkUser();
  }

  //import getDevSidebar from '../../customApp/sidebar';
  handleClick = (e) => {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === "MobileView") {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange = (newOpenKeys) => {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }

  getAncestorKeys = key => {
    const map = {
      sub3: ["sub2"]
    };
    return map[key] || [];
  };



  render() {
    const { toggleCollapsed, user, url, customizedTheme, locale, getUser, checkUser, app } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const mode = collapsed === false ? "vertical" : "inline";
    let getMenuItem;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };

        const submenuStyle = {
      backgroundColor: "rgba(0,0,0,0.3)",
      color: customizedTheme.textColor
    };
    const submenuColor = {
      color: customizedTheme.textColor
    };


    getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, label, leftIcon, children, icon } = singleOption;
    const url = stripTrailingSlash(this.props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              <span className="nav-text">
                 <IntlMessages id={label} />
              </span>
            </span>
          }
        >
          {children.map(child => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  <IntlMessages id={child.label} />
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              {icon && icon }<IntlMessages id={label} />
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };

    return (
      <TopbarWrapper>
      {user.email &&
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">

            <a href="/dashboard" style={{marginRight:50}}><img width="130" src="/static/media/logo-inverted.cba16e4e.png" /> </a>


            {user.user_level == 0 && <div> <Menu
              onClick={this.handleClick}
              mode="horizontal"
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {options.map(singleOption =>
                getMenuItem({ submenuStyle, submenuColor, singleOption })
              )}
              
            </Menu></div>
            }
           
          </div>

          <ul className="isoRight">
            

            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser locale={locale} user={user} />
            </li>
          </ul>
        </Header>
       }
      </TopbarWrapper> 
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    app: state.App,
    locale: state.LanguageSwitcher.language.locale,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
    user: state.User.user
  }),
  { toggleCollapsed , changeOpenKeys, changeCurrent, getUser, checkUser}
)(Topbar);


/* <li className="isoSearch">
              <TopbarSearch locale={locale} />
            </li>

            <li
              onClick={() => this.setState({ selectedItem: "notification" })}
              className="isoNotify"
            >
              <TopbarNotification locale={locale} />
            </li>

            <li
              onClick={() => this.setState({ selectedItem: "message" })}
              className="isoMsg"
            >
              <TopbarMessage locale={locale} />
            </li>
            <li
              onClick={() => this.setState({ selectedItem: "addToCart" })}
              className="isoCart"
            >
              <TopbarAddtoCart url={url} locale={locale} />
            </li>

*/