import { users } from "./reducer";
import userActions from "../user/actions";
import config from '../../settings/index';
const apiurl = config.apiUrl;
function ascendingSort(contact1, contact2) {
  const name1 = contact1.name ? contact1.name.toUpperCase() : "~";
  const name2 = contact2.name ? contact2.name.toUpperCase() : "~";
  return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

const usersActions = {
  GET_USERS: "GET_USERS",
  ADD_CONTACT: "ADD_CONTACT",
  EDIT_USERS: "EDIT_USERS",
  SAVE_CONTACT: "SAVE_CONTACT",
  DELETE__CONTACT: "DELETE__CONTACT",
  CHANGE_CONTACT: "CHANGE_CONTACT",
  EDIT_VIEW: "EDIT_VIEW",
  IS_LOADING: "IS_LOADING",
  changeUser: id => ({
    type: usersActions.CHANGE_CONTACT,
    id
  }),
  setLoading:loading =>({
   type: usersActions.IS_LOADING,
   loading
  }),
  getAllUsers: (business_ids, user_level) =>{
    return (dispatch, getState) => {
      return fetch(`${apiurl}/users`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_USERS", payload: body });
          }
          dispatch({ type: "IS_LOADING", loading: false });
        });
      };
  },
  editUser: newContact => {
    return (dispatch, getState) => {
      const users = getState().Users.users;
      const newContacts = [];
      users.forEach(contact => {
        if (contact.id === newContact.id) {
          newContacts.push(newContact);
        } else {
          newContacts.push(contact);
        }
      });
      dispatch({
        type: usersActions.EDIT_USERS,
        users: newContacts.sort(ascendingSort)
      });
    };
  },
  deleteUser: user => {
    return (dispatch, getState) => {
      const users = getState().Users.users;
      const selectedId = getState().Users.selectedId;
      const newContacts = [];
      users.forEach(contact => {
        if (contact.id === user.id) {
        } else {
          newContacts.push(contact);
        }
      });
      (async () => {
          const rawResponse = await fetch(`${apiurl}/authenticate/adminDeleteUser`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email: user.email,
            })
          });
          const content = await rawResponse.json();

          //dispatch(userActions.getUser(content));
        })();


      (async () => {
        const rawResponse = await fetch(`${apiurl}/users/${user.id}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
//        const content = await rawResponse;
      })();

      dispatch({
        type: userActions.USER_DELETED,
        activity : true,
        selectedId : selectedId
      })

      setTimeout(()=>{
        dispatch({
          type:userActions.USER_DELETED,
          activity: false,
          selectedId : selectedId
        })
      }, 2000)
      // dispatch({
      //   type: usersActions.DELETE__CONTACT,
      //   contacts: newContacts,
      //   selectedId: user.id === selectedId ? undefined : selectedId
      // });
    };
  },
  saveUser:(contact, id) => {
    return (dispatch, getState) => {
      (async () => {
        const rawResponse = await fetch(`${apiurl}/users/${contact.id}`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(contact)
        });

        dispatch({
          type: userActions.USER_DELETED,
          activity : true,
          selectedId : id
        })

        setTimeout(()=>{
          dispatch({
            type:userActions.USER_DELETED,
            activity: false,
            selectedId : id
          })
        }, 2000)

//        const content = await rawResponse;
      })();
    };
  },
  viewChange: view => ({
    type: usersActions.EDIT_VIEW,
    view
  })
};
export default usersActions;
