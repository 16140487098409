import userActions from "./actions";
import config from '../../settings';

const user = {}; 


const initState = {
  user,
  selectedId: user.id,
  editView: false
};

export default function contactReducer(state = initState, action) {
  switch (action.type) {
    case userActions.CHANGE_USER:
      return {
        ...state,
        selectedId: action.id,
        editView: false
      };
    case userActions.ADD_USER:
      return {
        ...state,
        user: action.user,
        selectedId: action.selectedId,
    };
    case userActions.NEW_USER_ADDED:
    return {
      ...state,
      userAdded: action.activity
    }
    case userActions.USER_DELETED:
    return {
      ...state,
      userDeleted: action.activity
    }
    case userActions.ADD_USER_ERROR: 
      return {
        ...state,
        error: action.error
    };
    case userActions.GET_USER:
      return {
        ...state,
        user: action.user
    };
    case userActions.NEW_PASSWORD_UPDATED: 
      return {
        ...state,
        passwordNew : action.password
    };
    case userActions.EDIT_USER:
      return {
        ...state,
        user: action.user
    };
    case userActions.DELETE__USER:
      return {
        ...state,
        user: action.user,
        selectedId: action.selectedId
      };
    case userActions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view
      };
    default:
      return state;
  }
}

export { user };


// // TodosContainer.js

// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { fetchTodos } from '../actions';

// class TodosContainer extends Component {
//   componentDidMount() {
//     // When container was mounted, we need to start fetching todos.
//     this.props.fetchTodos();
//   }

//   render() {
//     // In some simple cases, it is not necessary to create separate `Todos` component. You can put todos markup directly here.
//     return <Todos items={this.props.todos} />
//   }
// }

// // actions.js

// export function fetchTodos() {
//   // Instead of plain objects, we are returning function.
//   return function(dispatch) {
//     // Dispatching REQUEST action, which tells our app, that we are started requesting todos.
//     dispatch({
//       type: 'FETCH_TODOS_REQUEST'
//     });
//     return fetch('/api/todos')
//       // Here, we are getting json body(in our case it will contain `todos` or `error` prop, depending on request was failed or not) from server response
//       // And providing `response` and `body` variables to the next chain.
//       .then(response => response.json().then(body => ({ response, body })))
//       .then(({ response, body }) => {
//         if (!response.ok) {
//           // If request was failed, dispatching FAILURE action.
//           dispatch({
//             type: 'FETCH_TODOS_FAILURE',
//             error: body.error
//           });
//         } else {
//           // When everything is ok, dispatching SUCCESS action.
//           dispatch({
//             type: 'FETCH_TODOS_SUCCESS',
//             todos: body.todos
//           });
//         }
//       });
//   }
// }

// //store

// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import rootReducer from './reducers/index';

// const store = createStore(
//   rootReducer,
//   applyMiddleware(thunk)
// );

// // This function is used to convert redux global state to desired props.
// function mapStateToProps(state) {
//   // `state` variable contains whole redux state.
//   return {
//     // I assume, you have `todos` state variable.
//     // Todos will be available in container component as `this.props.todos`
//     todos: state.todos
//   };
// }

// // This function is used to provide callbacks to container component.
// function mapDispatchToProps(dispatch) {
//   return {
//     // This function will be available in component as `this.props.fetchTodos`
//     fetchTodos: function() {
//       dispatch(fetchTodos());
//     }
//   };
// }

// // We are using `connect` function to wrap our component with special component, which will provide to container all needed data.
// export default connect(mapStateToProps, mapDispatchToProps)(TodosContainer);






// // reducers.js

// import { combineReducers } from 'redux';

// const INITIAL_STATE = {
//   items: [],
//   isFetching: false,
//   error: undefined
// };

// function todosReducer(state = INITIAL_STATE, action) {
//   switch (action.type) {
//     case 'FETCH_TODOS_REQUEST':
//       // This time, you may want to display loader in the UI.
//       return Object.assign({}, state, {
//         isFetching: true
//       });
//     case 'FETCH_TODOS_SUCCESS':
//       // Adding derived todos to state
//       return Object.assign({}, state, {
//         isFetching: false,
//         todos: action.todos
//       });
//     case 'FETCH_TODOS_FAILURE':
//       // Providing error message to state, to be able display it in UI.
//       return Object.assign({}, state, {
//         isFetching: false,
//         error: action.error
//       });
//     default:
//       return state;
//   }
// }

// export default combineReducers({
//   todos: todosReducer
// });
