import { company } from "./reducer";
import config from "../../settings/index";
import axios from "axios";
const apiurl = config.apiUrl;
function ascendingSort(contact1, contact2) {
  const name1 = contact1.name ? contact1.name.toUpperCase() : "~";
  const name2 = contact2.name ? contact2.name.toUpperCase() : "~";
  return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

const companyActions = {
  ADD_COMPANY: "ADD_COMPANY",
  GET_COMPANY: "GET_COMPANY",
  EDIT_COMPANY: "EDIT_COMPANY",
  SAVE_COMPANY: "SAVE_COMPANY",
  DELETE__COMPANY: "DELETE__COMPANY",
  CHANGE_COMPANY: "CHANGE_COMPANY",
  EDIT_VIEW: "EDIT_VIEW",
  IS_LOADING: "IS_LOADING",
  setLoading: (loading) => ({
    type: companyActions.IS_LOADING,
    loading,
  }),
  changeCompany: (id) => {
    return (dispatch, getState) => {
      dispatch({
        type: companyActions.CHANGE_COMPANY,
        id,
      });
    };
  },
  getCompany: (user) => {
    return (dispatch, getState) => {
      let url;
      if (user.user_level == 0) {
        url = `${apiurl}/companies/`;
      }

      // if (user.user_level == 1) {
      //   url = `${apiurl}/company/${company_ids}`;
      // }
      return fetch(url)
        .then((response) =>
          response.json().then((body) => ({ response, body }))
        )
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: "USER_ERROR",
              error: body.error,
            });
          } else {
            dispatch({ type: "GET_COMPANY", payload: body });
          }
          dispatch({ type: "IS_LOADING", loading: false });
        });
    };
  },
  addCompany: (userform) => {
    return axios.post(`${apiurl}/companies/`, userform)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  editCompany: (newCompany) => {
    return (dispatch, getState) => {
      const company = getState().Company.company;
      const newCompany = [];
      company.forEach((company) => {
        if (company.id === newCompany.id) {
          newCompany.push(newCompany);
        } else {
          newCompany.push(company);
        }
      });
      dispatch({
        type: companyActions.EDIT_COMPANY,
        company: newCompany.sort(ascendingSort),
      });
    };
  },
  deleteCompany: (id) => {
    return (dispatch, getState) => {
      const company = getState().Company.company;
      const selectedId = getState().Company.selectedId;
      const userid = getState().User.user.id;
      const newCompany = [];
      company.forEach((b) => {
        if (b.id === id) {
        } else {
          newCompany.push(b);
        }
      });
      (async () => {
        const rawResponse = await fetch(`${apiurl}/companies/${id}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        //        const content = await rawResponse;
      })();
      dispatch({
        type: companyActions.DELETE__COMPANY,
        company: newCompany,
        selectedId: id === selectedId ? undefined : selectedId,
      });
    };
  },
  saveCompany: (company) => {
    return (dispatch, getState) => {
      (async () => {
        const rawResponse = await fetch(
          `${apiurl}/companies/${company.id}`,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(company),
          }
        );
        //        const content = await rawResponse;
      })();
    };
  },
  viewChange: (view) => {
    return (dispatch, getState) => {
      dispatch({
        type: companyActions.EDIT_VIEW,
        view,
      });
    };
  },
};
export default companyActions;
