import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import usersSagas from './users/saga';
import contactsSagas from './contacts/saga';
import businessSagas from './business/saga';
import userSagas from './user/saga';
import mailSagas from './mail/saga';
import chatSagas from './chat/sagas';
import devSagas from '../customApp/redux/sagas';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    usersSagas(),
    contactsSagas(),
    businessSagas(),
    userSagas(),
    mailSagas(),
    chatSagas(),
    
  ]);
}
