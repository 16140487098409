import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';

export function* addUser() {
  yield takeEvery(actions.ADD_CONTACT, function*() {});
}
export function* getAllUsers() {
  yield takeEvery(actions.GET_USERS, function*() {});
}
export function* saveUser() {
  yield takeEvery(actions.SAVE_CONTACT, function*() {});
}
export function* editUser() {
  yield takeEvery(actions.EDIT_USERS, function*() {});
}
export function* deleteUser() {
  yield takeEvery(actions.DELETE__CONTACT, function*() {});
}
export default function* rootSaga() {
  yield all([
    fork(addUser), 
    fork(editUser),
    fork(saveUser), 
    fork(deleteUser),
    fork(getAllUsers)
  ]);
}
