import Auth from './auth/reducer';
import App from './app/reducer';
import Mails from './mail/reducer';
import Calendar from './calendar/reducer';
import Box from './box/reducer';
import Users from './users/reducer';
import Contacts from './contacts/reducer';
import Business from './business/reducer';
import Company from './company/reducer';
import User from './user/reducer';
import Chat from './chat/reducers';
import DynamicChartComponent from './dynamicEchart/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import DevReducers from '../customApp/redux/reducers';
import Articles from './articles/reducers';
import ThemeSwitcher from './themeSwitcher/reducer';

export default {
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Mails,
  Calendar,
  Box,
  Users,
  Contacts,
  Business,
  Company,
  User,
  Chat,
  DynamicChartComponent,
  ...DevReducers,
};
