import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';
import customRoutes from '../../customApp/router';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../Home/index.js')),
  },
  {
    path: 'admin',
    component: asyncComponent(() => import('../Company')),
  },
  {
    path: 'company/:companyId/buildings',
    component: asyncComponent(() => import('../Business')),
  },
  {
    path: 'company/:companyId/building/:id/:page',
    component: asyncComponent(() => import('../Business')),
  },
  {
    path: 'company/:companyId',
    component: asyncComponent(() => import('../Company')),
  },
  {
    path: 'buildings',
    component: asyncComponent(() => import('../Business')),
  },
  {
    path: 'business',
    component: asyncComponent(() => import('../Business')),
    restricted: null
  },
  {
    path: 'building/:id',
    component: asyncComponent(() => import('../Business')),
    restricted: null
  },
  {
    path: 'building/:id/:page',
    component: asyncComponent(() => import('../Business')),
    restricted: null
  },
  {
    path: 'user/:userId',
    component: asyncComponent(() => import('../User')),
    restricted : [5]
  },
  {
    path: 'users',
    component: asyncComponent(() => import('../Users')),
    restricted : [0,1]
  },
  {
    path: 'companies',
    component: asyncComponent(() => import('../Company')),
    restricted : [0,1]
  },
  {
    path: 'contacts',
    component: asyncComponent(() => import('../Contacts')),
  },
  ...customRoutes,
];

class AppRouter extends Component {
  render() {
    const { url, style, user } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, restricted, ...otherProps } = singleRoute;          
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              restricted={singleRoute.restricted}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
