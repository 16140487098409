import businessActions from "./actions";

const business = {};
const amenities = {};
const photos = {};
const floorPlans = {};
const apartments = {};
const apartmentPhotos = {};
const apartmentFloorPlans = {};

const initState = {
  business,
  amenities,
  photos,
  apartments,
  apartmentPhotos,
  apartmentFloorPlans,
  editView: false
};

export default function contactReducer(state = initState, action) {
  switch (action.type) {
    case businessActions.CHANGE_CONTACT:
      return {
        ...state,
        selectedId: action.id,
        editView: false
      };
    case businessActions.GET_CONTACTS:
      return {
        ...state,
        business: action.payload,
        editView: false
      };
    case businessActions.IS_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case businessActions.GET_ALL_AMENITIES:
      return {
        ...state,
        amenities: action.payload,
        editView: false
      }; 
    case businessActions.GET_ALL_APARTMENTS:
      return {
        ...state,
        apartments: action.payload,
        editView: false
      };       
    case businessActions.GET_ALL_APARTMENT_PHOTOS:
      return {
        ...state,
        apartmentPhotos: action.payload,
        editView: false
    };  
    case businessActions.GET_ALL_APARTMENT_FLOORPLANS:
      return {
        ...state,
        apartmentFloorPlans: action.payload,
        editView: false
    };         
    case businessActions.GET_ALL_PHOTOS:
      return {
        ...state,
        photos: action.payload,
        editView: false
      };  
    case businessActions.GET_ALL_FLOORPLANS:
      return {
        ...state,
        floorPlans: action.payload,
        editView: false
      };                
    case businessActions.ADD_CONTACT:
      return {
        ...state,
        business: action.business,
        selectedId: action.selectedId,
        editView: true
      };
    case businessActions.EDIT_CONTACT:
      return {
        ...state,
        business: action.business
      };
    case businessActions.SAVE_CONTACT:
      return {
        ...state,
        business: action.business
      };  
    case businessActions.DELETE__CONTACT:
      return {
        ...state,
        business: action.business,
        selectedId: action.selectedId
      };
    case businessActions.NEW_BUILDING_ADDED: 
      return {
        ...state,
        buildingAdded: action.activity
      }
    case businessActions.EDIT_VIEW:
      return {
        ...state,
        editView: action.view
      };
    default:
      return state;
  }
}

export { business, amenities, photos, floorPlans, apartments, apartmentPhotos, apartmentFloorPlans };
