import { business } from "./reducer";
import config from '../../settings/index';
import { createBrowserHistory } from 'history';
import axios from 'axios';

const apiurl = config.apiUrl;
function ascendingSort(contact1, contact2) {
  const name1 = contact1.name ? contact1.name.toUpperCase() : "~";
  const name2 = contact2.name ? contact2.name.toUpperCase() : "~";
  return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
}

const businessActions = {
  ADD_CONTACT: "ADD_CONTACT",
  GET_CONTACTS: "GET_CONTACTS",
  GET_ALL_AMENITIES: "GET_ALL_AMENITIES",
  GET_ALL_APARTMENTS: "GET_ALL_APARTMENTS",
  GET_ALL_APARTMENT_PHOTOS: "GET_ALL_APARTMENT_PHOTOS",
  GET_ALL_APARTMENT_FLOORPLANS: "GET_ALL_APARTMENT_FLOORPLANS",
  GET_ALL_PHOTOS: "GET_ALL_PHOTOS",
  GET_ALL_FLOORPLANS: "GET_ALL_FLOORPLANS",
  EDIT_CONTACT: "EDIT_CONTACT",
  SAVE_CONTACT: "SAVE_CONTACT",
  DELETE__CONTACT: "DELETE__CONTACT",
  CHANGE_CONTACT: "CHANGE_CONTACT",
  EDIT_VIEW: "EDIT_VIEW",
  NEW_BUILDING_ADDED : "NEW_BUILDING_ADDED",
  IS_LOADING: "IS_LOADING",
  setLoading: loading => ({
    type: businessActions.IS_LOADING,
    loading
  }),
  changeBusiness: id => {
    return (dispatch, getState) => {
      dispatch({
        type: businessActions.CHANGE_CONTACT,
        id
      });
      
      dispatch(businessActions.getAmenities(id));
      dispatch(businessActions.getApartments(id));
      dispatch(businessActions.getPhotos(id));
      //    dispatch(businessActions.getApartmentPhotos(id));
      dispatch(businessActions.getApartmentFloorPlans(id));
      dispatch(businessActions.getFloorPlans(id));
    }
  },
  getBusinessById : (id) => {
    return axios.get(`${apiurl}/buildings/${id}`)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  getBusiness: (companyId) => {        
    const url = `${apiurl}/buildings/company/${companyId}`;
    return (dispatch, getState) => {
      if(companyId != undefined){
        return fetch(url)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {          
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_CONTACTS", payload: body });
          }
          dispatch({ type: "IS_LOADING", loading: false });
        });  
      }
      else{
        return '';
      }
      
    };
  },
  getAmenities: (business_id) => {
    return (dispatch, getState) => {
      return fetch(`${apiurl}/amenities/${business_id}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_AMENITIES", payload: body });
          }
        });
    };
  },
  getPhotos: (business_id, filterobj = null) => {
    return (dispatch, getState) => {
      return fetch(`${apiurl}/photos/getAll`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          businessId: business_id,
          filterobj,
        })
      })
        .then(response => response.json().then(body => ({ response, body })))          
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_PHOTOS", payload: body });
          }
        });
    };
  },
  getApartments: (business_id) => {
    return (dispatch, getState) => {
      return fetch(`${apiurl}/apartments/${business_id}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_APARTMENTS", payload: body });
          }
        });
    };
  },
  getFloorPlans: (business_id) => {
    return (dispatch, getState) => {
      return fetch(`${apiurl}/floorplans/${business_id}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_FLOORPLANS", payload: body });
          }
        });
    };
  },
  addAmenity: (amenity) => {
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiurl}/amenities`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            business_id: amenity.business_id,
            type: amenity.type,
            name: amenity.name
          })
        });
        const content = await rawResponse.json();
        dispatch(businessActions.getAmenities(amenity.business_id));
      })();
    }
  },
  addPhoto: (photo) => {
    console.log('this is the photo', photo);
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiurl}/photos`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            business_id: photo.business_id,
            type: photo.type,
            name: photo.name,
            url: photo.url,
            uid: photo.uid
          })
        });
        const content = await rawResponse.json();
        dispatch(businessActions.getPhotos(photo.business_id));
      })();
    }
  },
  addApartment: (apartment, business) => {
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiurl}/apartments`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            business_id: business.id,
            title: apartment.title,
            apt_number: apartment.apt_number,
            on_market: apartment.on_market,
            bedrooms: apartment.bedrooms,
            bathrooms: apartment.bathrooms,
            price: apartment.price,
            availability: apartment.availability,
            amenities: apartment.amenities,
            description: apartment.description,
            size: apartment.size,
            featured: apartment.featured
          })
        });
        const content = await rawResponse.json();
        dispatch(businessActions.getApartments(business.id));
      })();
    }
  },
  deleteAmenity: (amenity) => {
    (async () => {
      const rawResponse = await fetch(`${apiurl}/amenities/${amenity.id}`, {
        method: 'DELETE',
        data: {},
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
    })();
  },
  addFloorPlan: (floorPlan) => {
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiurl}/floorplans`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            business_id: floorPlan.business_id,
            type: floorPlan.type,
            name: floorPlan.name,
            url: floorPlan.url,
            uid: floorPlan.uid
          })
        });
        const content = await rawResponse.json();
        dispatch(businessActions.getFloorPlans(floorPlan.business_id));
      })();
    }
  },
  getApartmentPhotos: (businessId, apartmentId) => {
    return (dispatch, getState) => {
      return fetch(`${apiurl}/photos/${businessId}/${apartmentId}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_APARTMENT_PHOTOS", payload: body });
          }
        });
    };
  },
  getApartmentFloorPlans: (businessId, apartmentId) => {
    return (dispatch, getState) => {
      return fetch(`${apiurl}/floorPlans/${businessId}/${apartmentId}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({ type: "GET_ALL_APARTMENT_FLOORPLANS", payload: body });
          }
        });
    };
  },
  deletePhoto: (photo) => {
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiurl}/photos/${photo.id}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        dispatch(businessActions.getPhotos(photo.business_id));
      })();
    }
  },
  deleteApartment: (apartment) => {
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiurl}/apartments/${apartment.id}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        dispatch(businessActions.getApartments(apartment.business_id));
      })();
    }
  },
  deleteFloorPlan: (floorPlan) => {
    return function (dispatch, getState) {
      (async () => {
        const rawResponse = await fetch(`${apiurl}/floorplans/${floorPlan.id}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        dispatch(businessActions.getFloorPlans(floorPlan.business_id));
      })();
    }
  },
  addBusiness: (businessform) => {
     return (dispatch, getState) => {
      (async () => {
        const rawResponse = await fetch(`${apiurl}/buildings`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(businessform)
        });
        const content = await rawResponse.json();

        dispatch({
          type: businessActions.NEW_BUILDING_ADDED,
          activity: true
        });

        setTimeout(()=>{
          dispatch({
            type:businessActions.NEW_BUILDING_ADDED,
            activity: false,
          })
        }, 2000)
        
      })();
    };
  },
  editBusiness: (newBusiness) => {
    return (dispatch, getState) => {
      const business = getState().Business.business;
      const newBusiness = [];
      business.forEach(business => {
        if (business.id === newBusiness.id) {
          newBusiness.push(newBusiness);
        } else {
          newBusiness.push(business);
        }
      });
      dispatch({
        type: businessActions.EDIT_CONTACT,
        business: newBusiness.sort(ascendingSort)
      });
    };
  },
  editPhoto: (photo) => {
    return (dispatch, getState) => {
      (async () => {
        const rawResponse = await fetch(`${apiurl}/photos/${photo.id}`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(photo)
        });
        //        const content = await rawResponse;
      })();
    };
  },
  editApartment: (apartment) => {
    return (dispatch, getState) => {
      (async () => {
        const rawResponse = await fetch(`${apiurl}/apartments/${apartment.id}`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(apartment)
        });
        //        const content = await rawResponse;
      })();
    };
  },
  editFloorPlan: (floorPlan) => {
    return (dispatch, getState) => {
      (async () => {
        const rawResponse = await fetch(`${apiurl}/floorplans/${floorPlan.id}`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(floorPlan)
        });
        //        const content = await rawResponse;
      })();
    };
  },
  deleteBusiness: (id, selectedCompany) => {
    return (dispatch, getState) => {
      const business = getState().Business.business;
      // const selectedId = getState().Business.selectedId;
      // const userid = getState().User.user.id;
      // const newBusiness = [];
      

      
      (async () => {
        const rawResponse = await fetch(`${apiurl}/buildings/${id}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
            const content = await rawResponse;
            //businessActions.getBusiness(selectedCompanyId);
            window.location = "/dashboard/company/"+selectedCompany.id+"/buildings";
      })();

      // (async () => {
      //   const rawResponse = await fetch(`${apiurl}/company/businessDelete/${selectedCompany.id}`, {
      //     method: 'PUT',
      //     headers: {
      //       'Accept': 'application/json',
      //       'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify(buildings)
      //   });
      //   //        const content = await rawResponse;
      // })();
      // dispatch({
      //   type: businessActions.DELETE__CONTACT,
      //   business: newBusiness,
      //   selectedId: id === selectedId ? undefined : selectedId
      // });
      // window.location.href = '/dashboard/company/'+selectedCompany.id+'/buildings';
    };
  },
  saveBusiness: (contact) => {
    console.log('contact', contact);
    return (dispatch, getState) => {
      (async () => {
        const rawResponse = await fetch(`${apiurl}/buildings/${contact.id}`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(contact),
        });
        //        const content = await rawResponse;
      })();
    };
  },
  viewChange: (view) => {

    return (dispatch, getState) => {
      dispatch({
        type: businessActions.EDIT_VIEW,
        view
      });
    }
  }
};
export default businessActions;

