import { api } from '../../settings';
import { loginRequest } from "./saga";
import axios from "axios";
import promise from "redux-promise-middleware";
import config from '../../settings/index';
import {addUser} from '../user/actions';
const apiurl = config.apiUrl;


const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  CHECK_REGISTRATION: 'CHECK_REGISTRATION',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login : (data) => ({
    type: actions.LOGIN_SUCCESS,
    payload: data
  }),
  loginSuccess : (data) => ({
    type: actions.LOGIN_SUCCESS,
    payload : data
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
};
export default actions;




// const profile = localStorage.getItem('profile');
//   const access_token = localStorage.getItem('access_token');
//   const id_token = localStorage.getItem('id_token');
//   const expires_at = localStorage.getItem('expires_at');
//   const identities = localStorage.getItem('identities');
//   const user_id = localStorage.getItem('user_id').split('|')[1];
  

//   console.log(history);
//   console.log(authData);



//   fetch('http://localhost:5432/users/uid/'+user_id)
//   .then(function(response) {
//     return response.json()
//   })
//   .then(function(checkuid) {
//       if(!checkuid){
//         addUser(user_id, profile);
//       }
//   });


//   function addUser(uid, pfile){    
//     let profile = JSON.parse(pfile);
//     (async () => {
//       const rawResponse = await fetch('http://localhost:5432/users/register', {
//         method: 'POST',
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({f_name: profile.idTokenPayload.given_name, l_name: profile.idTokenPayload.family_name, email: profile.idTokenPayload.email, photo: profile.idTokenPayload.photo, user_id: uid })
//       });
//       const content = await rawResponse.json();
//     })();
//   }

