import config from '../../settings';
import axios from "axios";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { v4 as uuidv4 } from 'uuid';
import { user } from "./reducer";
const apiUrl = config.apiUrl;
const userActions = {
  ADD_USER: "ADD_USER",
  ADD_USER_ERROR: "ADD_USER_ERROR",
  GET_USER: "GET_USER",
  EDIT_USER: "EDIT_USER",
  CHECK_USER: "CHECK_USER",
  DELETE__USER: "DELETE__USER",
  CHANGE_USER: "CHANGE_USER",
  NEW_PASSWORD_UPDATED : "NEW_PASSWORD_UPDATED",
  NEW_USER_ADDED : "NEW_USER_ADDED",
  USER_DELETED: "USER_DELETED",
  EDIT_VIEW: "EDIT_VIEW",
  changeUser: id => ({
    type: userActions.CHANGE_USER,
    id
  }),
  updatePassword:  (passwordCreate, userSetup) => {
    return (dispatch, getState) => {
      return axios.post(`${apiUrl}/authenticate/changePassword`, {
        email: userSetup.email,
        loginSession : userSetup.data.loginSession,
        password : passwordCreate.password
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  },
  getUser: (user) => {
    return (dispatch, getState) => {

      let id;
      if(typeof(user) == 'object'){
        id = user.filter(u => u.Name == 'sub')[0].Value;
      }
      else{
        id = user;
      }


      return fetch(`${apiUrl}/users/uid/${id}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            dispatch({
              type: userActions.GET_USER,
              user: body
            });
          }
        });
      };
  },
  getUserByEmail : (email) =>{
    return (dispatch, getState) => {
      return fetch(`${apiUrl}/users/email/${email}`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if (!response.ok) {
            dispatch({
              type: 'USER_ERROR',
              error: body.error
            });
          } else {
            return body;
            // dispatch({
            //   type: userActions.GET_USER,
            //   user: body
            // });
          }
        });
      };

  },
  getToken: () => {
    return fetch(`${apiUrl}/users/auth/token`)
        .then(response => response.json().then(body => ({ response, body })))
        .then(({ response, body }) => {
          if(body){
              console.log('this is body token', body)
              return body.access_token;
          }
          else{
              console.log('error no token');
          }

        });
  },
  checkUser: () => {
   const getRealAuthData = JSON.parse(localStorage.getItem('getreal_authdata'));
   if(getRealAuthData != null){
     const id = getRealAuthData.authResult.username;
      return (dispatch, getState) => {
        return fetch(`${apiUrl}/users/uid/${id}`)
          .then(response => response.json().then(body => ({ response, body })))
          .then(({ response, body }) => {
            if (!response.ok) {
              dispatch({
                type: 'USER_ERROR',
                error: body.error
              });
            } else {
              if(body){
                dispatch({
                  type: userActions.GET_USER,
                  user: body
                });
              }
              else{
                //console.log('testtt', profile);
                //dispatch(userActions.addUser(profile, user_id));
              }
            }
          });
        };
   }
   else{
     window.location = "/"
   }
  },
  createCognitoUser : async (user) => {
        const rawResponse = await fetch(`${apiUrl}/authenticate/adminSignUp`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: user.email
          })
        });
        return rawResponse.json();
  },
  registerUser : (user, company_id, user_id) => {
    return function (dispatch, getState){
      console.log('got here 2');
        (async () => {
          const rawResponse = await fetch(`${apiUrl}/users`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              f_name: user.f_name,
              l_name: user.l_name,
              email: user.email,
              user_level: user.user_level,
              business_id : user.business_id,
              user_id : user_id,
              company_id : user.company_id
            })
          });
          const content = await rawResponse.json();
          console.log('this is content', content);

          console.log('this is the content', content);
          dispatch({
            type: userActions.ADD_USER,
            user : content
          })
          //dispatch(userActions.getUser(content));
        })();
    }
  },
  addUser: (user, company_id, business_id) => {
    return function (dispatch, getState) {
          userActions.createCognitoUser(user).then(info => {
            console.log('this is info', info)
            if(info.User){
              (async () => {
                const rawResponse = await fetch(`${apiUrl}/users`, {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    f_name: user.f_name,
                    l_name: user.l_name,
                    email: user.email,
                    photo: user.photo,
                    phone: user.phone,
                    user_level: user.user_level,
                    business_id : business_id ? business_id : null,
                    company_id : company_id ? company_id : null,
                    user_id : info.User.Username,
                  })
                });
                const content = await rawResponse.json();
                console.log('this is content', content);

                 dispatch({
                  type: userActions.NEW_USER_ADDED,
                  activity : true,
                  selectedId : company_id
                })

                setTimeout(()=>{
                  dispatch({
                    type:userActions.NEW_USER_ADDED,
                    activity: false,
                    selectedId : company_id
                  })
                }, 2000)
                //dispatch(userActions.getUser(content));
              })();
            }
            else{
              dispatch({
                type: userActions.ADD_USER_ERROR,
                error : info.message
              })
            }
          });
    }
  },
  editUser: newUser => {
    return (dispatch, getState) => {
      const _user = getState().User.user;
      dispatch({
        type: userActions.EDIT_USER,
        user: _user
      });
    };
  },
  deleteUser: user => {
    alert('got here');
    return (dispatch, getState) => {
      const _user = getState().User.user;
      const selectedId = getState().User.selectedId;

      (async () => {
          const rawResponse = await fetch(`${apiUrl}/authenticate/adminDeleteUser`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email: user.email,
            })
          });
          const content = await rawResponse.json();
          console.log('this is content', content);

          //dispatch(userActions.getUser(content));
        })();



      dispatch({
        type: userActions.DELETE__USER,
        contacts: _user,
        selectedId: user.id === selectedId ? undefined : selectedId
      });
    };
  },
  viewChange: view => ({
    type: userActions.EDIT_VIEW,
    view
  })
};
export default userActions;
